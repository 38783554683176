// general import
import React from 'react'
import './Contact.css'

// import link
import { Link } from 'react-router-dom'

// import default components
import Footer from '../../Components/Footer/Footer'
import Subscribe from '../../Components/Subscribes/Subscribe'

// import images
import FacebookIcon from '../../Assets/Images/facebook.svg'
import TwitterIcon from '../../Assets/Images/twitter.svg'
import InstagramIcon from '../../Assets/Images/instagram.svg'
import YoutubeIcon from '../../Assets/Images/youtube.svg'
import ThanksIcon from '../../Assets/Images/thankyou-icon.svg'

// import contact form
import ContactForm from '../../Components/ContactForm/ContactForm'

// import the animation
import { motion as m } from 'framer-motion';


const Contact = () => {
  return (
    <m.div 
    initial = {{ opacity: 0 }}
    animate = {{ opacity: 1 }}
    transition = {{ duration: 0.75, ease: 'easeOut' }}
    >

      <section className="section contact-section">
        <div className="container small">

          {/* <div className="border-cover"></div> */}

          <div className="outer-content grid grid-cols-1 md:grid-cols-12 justify-center items-center gap-10 md:gap-16">

            <div className="contact-col col-span-6 md:col-span-5">
              <h4>Contact Info</h4>
              <hr className="no-line big-space"></hr>
              <p>E-mail</p>
              <Link to="/">
                <h5>info@getintouch.my</h5>
              </Link>
              <hr className="no-line small-space"></hr>
              <p>Phone</p>
              <Link to="/">
                <h5>+60112345678</h5>
              </Link>
              <hr className="no-line small-space"></hr>
              <div className="footer-social-wrapper flex items-center gap-8">	
								<Link to="#"><img src={FacebookIcon} alt="" /></Link>
								<Link to="#"><img src={TwitterIcon} alt="" /></Link>
								<Link to="#"><img src={InstagramIcon} alt="" /></Link>
								<Link to="#"><img src={YoutubeIcon} alt="" /></Link>
							</div>
            </div>

            <div className="desc-col col-span-6 md:col-span-7">
              <h5>CONTACT US</h5>
              <h2>Get In Touch</h2>
              <p className="max-w-xl">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc diam augue, efficitur ut sollicitudin vel, cursus nec enim. Fusce dignissim porta sollicitudin.</p>
              <ContactForm />

              <div className="circle-icon custom-header-icon">
                <img src={ThanksIcon} alt="" />
              </div>
            </div>
          </div>

        </div>

      </section>

      <section className="section subscribe-section">
        <Subscribe />
      </section>

      <Footer />
    </m.div>
  )
};

export default Contact;