// general import
import React, {useState} from 'react'
import './Shop.css'

// import default components
import Footer from '../../Components/Footer/Footer'
import Subscribe from '../../Components/Subscribes/Subscribe'
import TabShops from '../../Components/Shops/Shops/Tabs/TabShops'

// import images
import ThanksIcon from '../../Assets/Images/thankyou-icon.svg'

// import link
// import { Link } from 'react-router-dom'

// import shop post
import Shops from '../../Components/Shops/Shops/Contents/Shops'

// import the animation
import { motion as m } from 'framer-motion';

const Shop = () => {
  const showItem = 6;
  const [noOfElement, setNoOfElement] = useState(showItem);
  const loadMore = () => {
    setNoOfElement(noOfElement + showItem);
  }

  return (
    <m.div 
    initial = {{ opacity: 0 }}
    animate = {{ opacity: 1 }}
    transition = {{ duration: 0.75, ease: 'easeOut' }}
    >

      <section className="section header-section">
        <div className="container">

          <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32">
            <div className="img-col">
              <img src={require("../../Assets/Images/blog-banner-04.png")} alt="" />
            </div>
            <div className="img-col-wrapper">
              <div className="desc-col">
                <h5>SHOP</h5>
                <h2>Grab A Trolley  <br /> and Have Fun!</h2>
                <p className="max-w-lg">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                <hr className="no-line btn-line"></hr>
              </div>
              <div className="circle-icon inner-header-icon">
                <img src={ThanksIcon} alt="" />
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="section blog-section">
        <div className="container">

          <div className="flex md:justify-center">
            <div className="title-c md:text-center pb-5">
              <h5>BLOG</h5>
              <h2>What’s New</h2>
            </div>
          </div>

        <TabShops />



        <div className="posts-container grid mx-auto grid-cols-1 md:grid-cols-3 gap-5 md:gap-5">
          {Shops.cardData?.slice(0, noOfElement)?.map((item, index) => {
            return (
              <div className="post-container" style={{ backgroundImage: `url(${item.bgUrl})` }} key={index}>
              <div className="post-content">
                <h4 className="post-title max-w-xs">{item.title}</h4>
              </div>
              </div>
            )
          })}
        </div>
          <hr className="no-line btn-line"></hr>
          <div className="btn-wrapper flex justify-center mt-16">
            <div className="btn secondary-btn">
              {noOfElement < Shops.cardData?.length && (
                <button className="btn-link" onClick={ loadMore }>
                  <span className="btn-label loading">LOAD MORE</span>
                </button>
              )}

            </div>
          </div>


        </div>
      </section>

      <section className="section subscribe-section">
        <Subscribe />
      </section>

      <Footer />

    </m.div>
  )
};

export default Shop;
