// import React from "react"
import "./Posts.css"
// import Post from "../Post/Post"

// import the bg blog posts image
import post1 from '../Images/post-01.png'
import post2 from '../Images/post-02.png'
import post3 from '../Images/post-03.png'
import post4 from '../Images/post-04.png'
import post5 from '../Images/post-05.png'
import post6 from '../Images/post-06.png'
  
// const Posts = () => {
//   const blogPosts = [
//     {
//       title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
//       bgUrl:
//         [post1],
//     },
//     {
//       title: "Cakes",
//       bgUrl:
//         [post2],
//     },
//     {
//       title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
//       bgUrl:
//       [post3],
//     },
//     {
//       title: "Lorem Ipsum Dolat of Sit Amet Consetetur", 
//       bgUrl:
//       [post4],
//     },
//     {
//       title: "Lorem Ipsum Dolat of Sit Amet Consetetur", 
//       bgUrl:
//       [post5],
//     },
//     {
//       title: "Lorem Ipsum Dolat of Sit Amet Consetetur", 
//       bgUrl:
//       [post6],
//     },
//   ];
  
//   return (
//     <div className="posts-container grid mx-auto grid-cols-1 md:grid-cols-3 gap-5 md:gap-5">
//       {blogPosts.map((post, index) => (
//         <Post key={index} index={index} post={post} />
//       ))}
//     </div>
//   );
// };

const Posts = {
  cardData: [
    {
      id: 1,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post1],
    },
    {
      id: 2,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post2],
    },
    {
      id: 3,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post3],
    },
    {
      id: 4,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post4],
    },
    {
      id: 5,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post5],
    },
    {
      id: 6,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post6],
    },
    {
      id: 7,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post1],
    },
    {
      id: 8,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post2],
    },
    {
      id: 9,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post2],
    },
    {
      id: 10,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post3],
    },
    {
      id: 11,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post4],
    },
    {
      id: 12,
      title: "Lorem Ipsum Dolat of Sit Amet Consetetur",
      bgUrl:
        [post5],
    },
  ]
}
  
export default Posts;