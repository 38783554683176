// general import
import React from 'react'
import './Footer.css'
import { Outlet, Link } from "react-router-dom";

import FacebookIcon from '../../Assets/Images/facebook.svg'
import TwitterIcon from '../../Assets/Images/twitter.svg'
import InstagramIcon from '../../Assets/Images/instagram.svg'
import YoutubeIcon from '../../Assets/Images/youtube.svg'

const Footer = () => {
  const today = new Date();
  return (
    <>
			<div className="footer">
				<div className="container medium">

					<div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32 pb-12">

						<div className="footer-col1">
							<div className="footer-logo pb-5">
								<Link to="/">
									<span className="footer-logo-font font-white font-bold text-2xl">LOGO</span>
								</Link>
							</div>
							<div className="footer-desc pb-5">
								<p className="max-w-lg">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy tif eirmod tempor Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.</p>
							</div>
							<div className="footer-social-wrapper flex items-center gap-8">	
								<Link to="#"><img src={FacebookIcon} alt="" /></Link>
								<Link to="#"><img src={TwitterIcon} alt="" /></Link>
								<Link to="#"><img src={InstagramIcon} alt="" /></Link>
								<Link to="#"><img src={YoutubeIcon} alt="" /></Link>
							</div>
						</div>

						<div className="footer-col2">
							<li>
								<Link to="/" className="footer-link">Home</Link>
							</li>
							<li>
								<Link to="/about" className="footer-link">About</Link>
							</li>
							<li>
								<Link to="/blog" className="footer-link">Blog</Link>
							</li>
							{/* <li>
								<Link to="/shop" className="footer-link">Shop</Link>
							</li> */}
							<li>
								<Link to="/contact" className="footer-link">Contact</Link>
							</li>
						</div>
					</div>

					<div className="copyright-wrapper text-center">
						<small>Copyright © {today.getFullYear()} Company ABC. All rights reserved Privacy policy , Terms and Conditions</small>
					</div>
				
				</div>
			</div>

      <Outlet />
    </>
  )
};

export default Footer;
