// general import
import React, { useState, useEffect, useRef } from 'react';
import './Home.css';

// import default components
import Footer from '../../Components/Footer/Footer';
import Subscribe from '../../Components/Subscribes/Subscribe';

// import images
import HelloIcon from '../../Assets/Images/hello-icon.svg';
import ThanksIcon from '../../Assets/Images/thankyou-icon.svg';

// import link
import { Link } from 'react-router-dom';

// import blog post
import Posts from '../../Components/Blog/Posts/Posts';

// import the animation
// import { motion as m } from 'framer-motion';
import gsap, { Power4 }  from 'gsap';
import Transition from '../../Components/Transition/Transition';

// aos
import AOS from 'aos';
import 'aos/dist/aos.css'


const Home = () => {

  // animation 
  const heroSection = gsap.timeline();
  const heroWrapper = useRef(null); // hero image wrapper
  const imageReveal = useRef(null);
  const imageReveal2 = useRef(null);
  const lineReveal = useRef(null);

  // preloader
  const [preLoader, setPreLoader] = useState(true);
  const [timer, setTimer] = useState(3);
  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
    setPreLoader(false);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000)
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear()
    }
  }, [timer]);

  // for text
  let subheader = useRef(null);
  let header = useRef(null);
  let text = useRef(null);

  // additional
  let mainBtn = useRef(null);
  let circleIcon = useRef(null);


  useEffect(() => {
    heroSection.from(heroWrapper.current, {
      delay: 1,
      opacity: 0
    }, "-=4")

    // reveal overlay
    heroSection.to(imageReveal.current, {
      duration: 1,
      delay: 1,
      y: "100%",
      stagger: .5
    }, "-=4")

    // the image
    heroSection.from(imageReveal2.current, {
      duration: 1,
      delay: 1,
      y: "100%",
      stagger: .5,
      opacity: 0,
      scale: 1.5,
      ease: Power4.easeOut
    }, "-=4")

    heroSection.from([subheader, header, text], {
      duration: 1,
      skewY: 15,
      delay: 1,
      y: 400,
      stagger: {
        amount: .2
      }
    }, "-=4")

    heroSection.from([mainBtn, circleIcon], {
      duration: .8,
      delay: 2,
      opacity: 0
    }, "-=4")

    heroSection.from(lineReveal.current, {
      duration: 2,
      scaleX: 0,
      ease: Power4.easeOut,
      clearProps: "all",
      delay: 2,
      opacity: 0
    }, "-=4")
  })

  // aos
  useEffect(() => {
    AOS.init();
  }, []);


  // for load more blogs
  const showItem = 6;
  const [noOfElement, setNoOfElement] = useState(showItem);
  const loadMore = () => {
    setNoOfElement(noOfElement + showItem);
  }


  return (
    <div>

    {preLoader? (
      <div className="loader-wrapper">
        <h1>Victoria</h1>
      </div>
    ) : (

      <div>

          <Transition timeline={heroSection} />

          <section className="section main-section section-home-animation-1">
            <div className="container">

              <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32">
                <div className="img-col hero-img-col" ref={ heroWrapper }>
                  <div className="image-reveal" ref={ imageReveal }></div>
                  <div ref={ imageReveal2 }>
                    <img src={require("../../Assets/Images/blog-banner-01.png")} alt="" />
                  </div>
                </div>
                <div className="img-col-wrapper">
                  <div className="desc-col">
                    <div className="text-line">
                      <h5 ref={el => subheader =el}>FOOD HEAVEN</h5>
                    </div>
                    <div className="text-line">
                      <h2 ref={el => header =el}>Grocery Store, <br /> Market Whatever</h2>
                    </div>
                    <div className="text-line">
                      <p ref={el => text =el} className="max-w-lg">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                    </div>
                    <hr className="no-line btn-line d-none-m"></hr>
                    <div className="btn-wrapper">
                      <div ref={el => mainBtn =el} className="btn main-btn">
                        <Link className="btn-link" to="/">
                          <span className="btn-label">SUBSCRIBE</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="circle-icon header-icon">
                    <img ref={el => circleIcon =el} src={HelloIcon} alt="" />
                  </div>
                </div>
              </div>

            <div className="line-animation">
              <div className="stripe" ref={ lineReveal }></div>
            </div>
            
            </div>
          </section>

          <section className="section our-story-section">
            <div className="container medium">

              <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32">
                <div className="desc-col">
                  <h5>OUR STORY</h5>
                  <h3>A Place Where</h3>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                  <hr className="no-line btn-line"></hr>
                  <div className="btn-wrapper">
                    <div className="btn secondary-btn">
                      <Link className="btn-link" to="/">
                        <span className="btn-label">LEARN MORE</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="img-col" data-aos="fade-up" data-aos-duration="2000">
                  <img src={require("../../Assets/Images/our-story.png")} alt="" />
                </div>
              </div>

            </div>

          </section>

          <section className="section featured-section d-none-section">
            <div className="container medium">

              <div className="flex justify-center">
                <div className="title-c pb-5">
                  <h5>AS FEATURED IN</h5>
                </div>
              </div>

              <div className="grid justify-items-center grid-cols-3 gap-4">
                <img src={require("../../Assets/Images/featured-1.png")} className="mb-4" alt="" />
                <img src={require("../../Assets/Images/featured-2.png")} className="mb-4" alt="" />
                <img src={require("../../Assets/Images/featured-3.png")} className="mb-4" alt="" />
                <img src={require("../../Assets/Images/featured-4.png")} className="mb-4" alt="" />
                <img src={require("../../Assets/Images/featured-5.png")} className="mb-4" alt="" />
                <img src={require("../../Assets/Images/featured-6.png")} className="mb-4" alt="" />
              </div>

            </div>
          </section>

          <section className="section creator-section green-bg">
            <div className="container medium">

              <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32">
                <div className="desc-col">
                  <h5 className="font-white">CREATOR</h5>
                  <h3 className="font-white">Meet Victoria</h3>
                  <p className="font-white">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                  <hr className="no-line btn-line"></hr>
                  <div className="btn-wrapper">
                    <div className="btn tertiary-btn">
                      <Link className="btn-link" to="/">
                        <span className="btn-label">LEARN MORE</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="img-col-wrapper">
                  <div className="img-col" data-aos="fade-up" data-aos-duration="2000">
                    <img src={require("../../Assets/Images/creator-profile-img.png")} alt="" />
                    <img src={ThanksIcon} alt="" className="inner-circle-icon thanks-icon" />
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section className="section blog-section">
            <div className="container">

              <div className="flex md:justify-center">
                <div className="title-c md:text-center pb-5">
                  <h5>BLOG</h5>
                  <h2>What’s New</h2>
                </div>
              </div>

              <div className="posts-container grid mx-auto grid-cols-1 md:grid-cols-3 gap-5 md:gap-5" data-aos="fade-up" data-aos-duration="2000">
                {Posts.cardData?.slice(0, noOfElement)?.map((item, index) => {
                  return (
                    <div className="post-container" style={{ backgroundImage: `url(${item.bgUrl})` }} key={index}>
                      <div className="post-content">
                        <h4 className="post-title max-w-xs">{item.title}</h4>
                      </div>
                    </div>
                  )
                })}
              </div>
              <hr className="no-line btn-line"></hr>
              <div className="btn-wrapper flex justify-center mt-16">
                <div className="btn secondary-btn">
                  {noOfElement < Posts.cardData?.length && (
                    <button className="btn-link" onClick={loadMore}>
                      <span className="btn-label loading">LOAD MORE</span>
                    </button>
                  )}

                </div>
              </div>

            </div>
          </section>

          <section className="section quotes-section">
            <div className="container medium">

              <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32">
                <div className="img-col-wrapper">
                  <div className="img-col" data-aos="fade-up" data-aos-duration="2000">
                    <img src={require("../../Assets/Images/Strawberry-Festival-Mold.png")} alt="" />
                  </div>
                </div>
                <div className="desc-col text-center">
                  <h3 className="header-light">“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et”</h3>
                  <h4>THE NEW YORK TIMES</h4>
                </div>
              </div>

            </div>
          </section>

          <section className="section subscribe-section">
            <Subscribe />
          </section>

          <Footer />

        </div>

    )}

    </div>
  )
};

export default Home;
