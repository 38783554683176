import { useState } from 'react'
import './Subscribe.css'


function Subscribe() {
	const [name, setName] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		alert(`The name you entered was: ${name}`);
	}

	return (
		<div>
			<div className="overlay-green"></div>
			<div className="container small">
				<div className="flex justify-center">
					<div className="subsc-wrapper pink-bg w-full md:w-2/4 p-5 md:p-5">
						<div className="text-center subsc-title">
							<h4>Subscribe for more updates!</h4>
						</div>
						<form className="flex flex-wrap items-center justify-center" onSubmit={handleSubmit}>
							<label>
								<input
									type="text"
									className="subscribe-input mb-5 md:mb-0 mr-0 md:mr-4 focus:border-current focus:ring-0"
									placeholder="Enter your e-mail here.."
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</label>
								<div className="submit-btn-wrapper">
									<button type="submit" className="submit-btn">
										<span className="btn-label">SUBMIT</span>
									</button>
								</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}


export default Subscribe