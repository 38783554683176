import React from "react";
import './QuotesSlider.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import { Autoplay, Navigation, Pagination, A11y } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const QuotesSlider = () => {
  return (
    <>
      <Swiper
        className="quotesSlider"
        grabCursor={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation, Pagination, A11y]}
        navigation
        pagination={{ clickable: true, dynamicBullets: true, }}
        slidesPerView={1}
        spaceBetween={50}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide className="quotes-slider-content">
          <h3 className="quotes-slider-title">"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, ullam"</h3>
          <h4 className="quotes-slider-subtitle">VOGUE</h4>
        </SwiperSlide>

        <SwiperSlide className="quotes-slider-content">
          <h3 className="quotes-slider-title">"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, ullam"</h3>
          <h4 className="quotes-slider-subtitle">VOGUE</h4>
        </SwiperSlide>

        <SwiperSlide className="quotes-slider-content">
          <h3 className="quotes-slider-title">"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, ullam"</h3>
          <h4 className="quotes-slider-subtitle">VOGUE</h4>
        </SwiperSlide>

        <SwiperSlide className="quotes-slider-content">
          <h3 className="quotes-slider-title">"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, ullam"</h3>
          <h4 className="quotes-slider-subtitle">VOGUE</h4>
        </SwiperSlide>

        <SwiperSlide className="quotes-slider-content">
          <h3 className="quotes-slider-title">"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, ullam"</h3>
          <h4 className="quotes-slider-subtitle">VOGUE</h4>
        </SwiperSlide>

        <SwiperSlide className="quotes-slider-content">
          <h3 className="quotes-slider-title">"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magni, ullam"</h3>
          <h4 className="quotes-slider-subtitle">VOGUE</h4>
        </SwiperSlide>

      </Swiper>
    </>
  );
};

export default QuotesSlider;