// import logo from './logo.svg';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Header/Navbar";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Blog from './Pages/Blog/Blog';
import Shop from './Pages/Shop/Shop';
import Contact from "./Pages/Contact/Contact";
import NoPage from "./Pages/NoPage/NoPage";

import './Assets/Css/main.css'
import './App.css';


// import the animation
// import { AnimatePresence } from "framer-motion";


export default function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="blog" element={<Blog />} />
          <Route path="shop" element={<Shop />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
