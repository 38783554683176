import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

const Result = () => {
  return (
    <p>Your message has been successfully sent. Catch up later!</p>
  );
};

const ContactForm = () => {
  const form = useRef();
  const [result, showResult] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_smsa419', 'template_wx9gl0g', form.current, 'TqjHgNRNX-NprPBS2')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
    showResult(true);
  };

  // hide confirmation text after sent message
  setTimeout(() => {
    showResult(false)
  }, 2000);

  return (
    <div>
      <div className="mt-9">

        <form ref={form} onSubmit={sendEmail}>
          <div className="grid grid-cols-1 gap-6 w-full md:max-w-xl">
            <div className="block">
              <input type="text" className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b border-black
                    focus:ring-0 focus:border-black
                  " placeholder="Your Name" required />
            </div>
            <div className="block">
              <input type="email" className="
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b border-black
                    focus:ring-0 focus:border-black
                  " placeholder="Your Email" required />
            </div>
            <div className="block">
              <textarea className="mt-0 block w-full px-0.5 border-0 border-b border-black focus:ring-0 focus:border-black" rows="2" placeholder="Your Message" required></textarea>
            </div>

            <div className="btn-wrapper flex pt-5">
              <div className="btn submit-btn">
                <div className="btn-link">
                  <input type="submit" className="btn-label submit-btn-label" value="Submit" />
                </div>
              </div>
            </div>


            <div classNameName="submit-confirmation pt-5">
              {result ? <Result /> : null}
            </div>

          </div>

        </form>

      </div>
    </div>
  )
}
export default ContactForm