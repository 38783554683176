// general import
import React, { useEffect, useRef } from 'react';
import { Outlet, Link } from "react-router-dom";
import './Navbar.css'

// import the animation
import gsap  from 'gsap';

const Navbar = () => {

  // animation 
  const header = gsap.timeline();
  let li1 = useRef(null);
  let li2 = useRef(null);
  let li3 = useRef(null);
  let li4 = useRef(null);





  useEffect(() => {
    header.from([li1, li2, li3, li4], {
      opacity: 0,
      duration: 1,
      delay: 1,
      y: 20,
      stagger: {
        amount: .6
      }
    })
  })

  return (
    <>
      <header className="section default-header">


        <div className="navbar">

          <div className="navbar-left">
            <div className="navbar-logo">
              {/* <img src={MenuIcon} alt="" /> */}
              <Link to="/">
                <span className="navbar-logo-font font-bold text-2xl">LOGO</span>
              </Link>
            </div>
          </div>

          <ul className="navbar-right">
            <li ref={el => li1 =el}>
              <Link to="/" className="menu-link">Home</Link>
            </li>

            <li ref={el => li2 =el}>
              <Link to="/about" className="menu-link">About</Link>
            </li>
            <li ref={el => li3 =el}>
              <Link to="/blog" className="menu-link">Blog</Link>
            </li>
            {/* <li>
              <Link to="/shop" className="menu-link">Shop</Link>
            </li> */}
            <li ref={el => li4 =el}>
              <Link to="/contact" className="menu-link">Contact</Link>
            </li>
          </ul>

        </div>
      </header>

      <Outlet />
    </>
  )
};

export default Navbar;
