// general import
import React from 'react'
import './About.css'

// import default components
import Footer from '../../Components/Footer/Footer'
import Subscribe from '../../Components/Subscribes/Subscribe'

// import images
import ThanksIcon from '../../Assets/Images/thankyou-icon.svg'

import QuotesSlider from '../../Components/QuotesSlider/QuotesSlider'

// import the animation
import { motion as m } from 'framer-motion';



const About = () => {
  return (
    <m.div 
    initial = {{ opacity: 0 }}
    animate = {{ opacity: 1 }}
    transition = {{ duration: 0.75, ease: 'easeOut' }}
    >

      <div data-scroll-container>

        <section className="section header-section" data-scroll-section>
          <div className="container">

            <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32">
              <div className="img-col">
                <img src={require("../../Assets/Images/blog-banner-02.png")} alt="" />
              </div>
              <div className="img-col-wrapper">
                <div className="desc-col">
                  <h5>ABOUT US</h5>
                  <h2>Where It All <br /> Begins and Rest..</h2>
                  <p className="max-w-lg">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                  <hr className="no-line btn-line d-none-m"></hr>
                </div>
                <div className="circle-icon inner-header-icon">
                  <img src={ThanksIcon} alt="" />
                </div>
              </div>
            </div>

          </div>
        </section>

        <section className="section our-story-section" data-scroll-section>
          <div className="container medium">

            <div className="flex md:justify-center">
              <div className="title-c text-center pb-5">
                <h5>OUR STORY</h5>
                <h2>For the Love Of Jell-O</h2>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-12 justify-center items-center gap-10 md:gap-20">

              <div className="img-col col-span-6 md:col-span-4 text-center">
                <img src={require("../../Assets/Images/creator-profile-img.png")} alt="" className="w-1/2 md:w-full mx-auto md:mx-0" />
              </div>

              <div className="desc-col col-span-6 md:col-span-8">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc diam augue, efficitur ut sollicitudin vel, cursus nec enim. Fusce dignissim porta sollicitudin. Mauris rhoncus tempus purus, vitae gravida orci varius quis. Fusce facilisis nibh in erat maximus bibendum. Proin congue tempus ex ut porta. Suspendisse quis orci sit amet nunc semper vulputate et in nisl. Quisque dapibus diam at enim finibus tristique.</p>
                <p>Phasellus viverra convallis magna eu commodo. Ut vitae posuere nunc. Nunc non magna libero. Quisque aliquet felis neque, eget ultricies turpis pellentesque vel. Aliquam vitae lorem ut lectus convallis fermentum. Nunc ultrices porta ex, quis bibendum justo pharetra at. Praesent lectus tellus, fermentum eget ultrices non, accumsan quis ante. Suspendisse blandit efficitur risus id sollicitudin. Vestibulum risus quam, condimentum quis massa consequat, vehicula lacinia libero. Praesent tincidunt velit et dolor lacinia, eu eleifend sem consectetur. Donec commodo turpis a efficitur consequat. Donec tempor odio sit amet arcu tempor, ac blandit enim egestas. Sed dignissim pellentesque odio, et bibendum velit viverra eu.</p>
                <p>Sed erat eros, condimentum vitae aliquam a, imperdiet mattis est. Quisque lobortis velit sed eros pretium, eu sollicitudin nibh dapibus. Mauris viverra suscipit turpis.</p>
              </div>

            </div>

          </div>

        </section>

        <section className="section quotes-section" data-scroll-section>
          <div className="container medium">

            <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10 md:gap-32">

              <div className="desc-col text-center order-first">
                <h3 className="header-light">“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore”</h3>
              </div>

              <div className="img-col-wrapper">
                <div className="img-col">
                  <img src={require("../../Assets/Images/quotes-01.png")} alt="" />
                </div>
              </div>

            </div>

          </div>
        </section>

        <section className="section featured-section" data-scroll-section>
          <div className="container medium">

            <div className="flex justify-center">
              <div className="title-c pb-5">
                <h5>AS FEATURED IN</h5>
              </div>
            </div>

            <div className="grid justify-items-center grid-cols-3 gap-4">
              <img src={require("../../Assets/Images/featured-1.png")} className="mb-4" alt="" />
              <img src={require("../../Assets/Images/featured-2.png")} className="mb-4" alt="" />
              <img src={require("../../Assets/Images/featured-3.png")} className="mb-4" alt="" />
              <img src={require("../../Assets/Images/featured-4.png")} className="mb-4" alt="" />
              <img src={require("../../Assets/Images/featured-5.png")} className="mb-4" alt="" />
              <img src={require("../../Assets/Images/featured-6.png")} className="mb-4" alt="" />
            </div>

          </div>
        </section>

        <section className="section feedback-section" data-scroll-section>
          <div className="container medium">

            <div className="flex md:justify-center">
              <div className="title-c text-center pb-5">
                <h5>RECOGNITION</h5>
                <h2>What People Say About Us</h2>
              </div>
            </div>

            <div className="quotes-swiper-container">
              <QuotesSlider />
            </div>





          </div>
        </section>

        <section className="section subscribe-section" data-scroll-section>
          <Subscribe />
        </section>

        <Footer />

      </div>

    </m.div>
  )
};

export default About;
